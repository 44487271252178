import {Badge, Button, Card, Col, Container, Form, Nav, Navbar, Row} from "react-bootstrap";
import logo from "../assets/img/logo.png";
import bg from "../assets/img/background.PNG";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useEffect, useState} from "react";
import { ActiveCampaignInputs, handleActiveCampaignSubmit } from 'active-campaign-react'
import { useForm } from 'react-hook-form';

const NavMenu = () => {
    return (
        <Navbar collapseOnSelect expand="lg" sticky="top">
            <Container>
                <Navbar.Brand href="#home">
    
                    <h1 style={{marginLeft:10,color:'#ffff'}}>SENSE HABITAT</h1>
                    {/*<img*/}
                    {/*    alt=""*/}
                    {/*    src={logo}*/}
                    {/*    height="10"*/}
                    {/*    className="d-inline-block align-top"*/}
                    {/*/>*/}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home"
                                  variant={"active"}
                                  onClick={() => {
                                        window.gtag('set', 'page_path', '/');
                                        window.gtag('event', 'page_view', {page_title: "Home"});
                                    }
                                  }
                        >Home</Nav.Link>
                        <Nav.Link href="#features"
                                  onClick={() => {
                                      window.gtag('set', 'page_path', '/expertise');
                                      window.gtag('event', 'page_view', {page_title: "Expertise"});
                                  }
                                  }>Why us</Nav.Link>
                        <Nav.Link href="#clients"
                                  onClick={() => {
                                      window.gtag('set', 'page_path', '/clients');
                                      window.gtag('event', 'page_view', {page_title: "Clients"});
                                  }
                                  }>Clients</Nav.Link>
                        <Nav.Link href="#projects"
                                  onClick={() => {
                                      window.gtag('set', 'page_path', '/projects');
                                      window.gtag('event', 'page_view', {page_title: "Projects"});
                                  }
                                  }>How we can help</Nav.Link>
                        <Nav.Link href="#pricing"
                                  onClick={() => {
                                      window.gtag('set', 'page_path', '/pricing');
                                      window.gtag('event', 'page_view', {page_title: "Pricing"});
                                  }
                                  }>Pricing</Nav.Link>
                        <Nav.Link href="#contact"
                                  onClick={() => {
                                      window.gtag('set', 'page_path', '/contact');
                                      window.gtag('event', 'page_view', {page_title: "Contact"});
                                  }
                                  }>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const Hero = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Container className={"hero"} id={"home"} style={{height:'100%', padding:'100px 40px 40px 40px'}}>
            <Slider {...settings} style={{marginBottom:40, height:'100%'}}>

            <div style={{height:'100%'}}>
                    <Row style={{minHeight:'500px'}}>
                        <Col>
                            <h1 style={{fontSize:'3em',paddingTop:100}}>HR Insight.</h1>
                            <p>
                                We help you explore and utilise your organisational data assets. The rsults will transform your thinking and give you deep insight into your workforce, ethical fabric and success factors.
                            </p>
                            <div>
                                <Badge style={{marginRight:5}}>Management Reporting</Badge>
                                <Badge style={{marginRight:5}}>Visualisation and reporting</Badge>
                                <Badge style={{marginRight:5}}>Digital transformation</Badge>
                                <Badge style={{marginRight:5}}>Workforce Analytics</Badge>
                                <Badge style={{marginRight:5}}>Power BI</Badge>
                                <Badge style={{marginRight:5}}>Demographics</Badge>
                                <Badge style={{marginRight:5}}>Competitive Intelligence</Badge>
                                <Badge style={{marginRight:5}}>Market positioning</Badge>
                                <p style={{paddingTop:20}}>
                                    <a className="btn btn-outline-primary" href={"#contact"}>Contact us to learn more</a>
                                </p>
                            </div>
                        </Col>
                        <Col xs lg="4"></Col>
                    </Row>
            </div>

            <div>
                    <Row style={{minHeight:'500px'}}>
                        <Col>
                            <div>
                                <h1 style={{fontSize:'3em'}}>Direction.</h1>
                                <p>
                                
                                </p>
                                <Badge style={{marginRight:5}}>Board reports</Badge>
                                <Badge style={{marginRight:5}}>Coaching</Badge>
                                <Badge style={{marginRight:5}}>KPI development</Badge>
                                <p style={{paddingTop:20}}>
                                    <a className="btn btn-outline-primary " href={"#contact"}>Contact us to learn more</a>
                                </p>
                            </div>
                        </Col>
                        <Col xs lg="4"></Col>
                    </Row>
            </div>

        </Slider>
        </Container>
    )
}

const Features = () => {
    return (
        <Container style={{padding:40}} id={"features"}>
            <h1 className="text-center" style={{marginBottom:20}}>Where we're good at</h1>
           <Row style={{padding:'40px 0'}}>
               <Col>
                   <Card style={{height:'100%'}}>
                       <Card.Body>
                           <Card.Title>Management consulting</Card.Title>
                           <Card.Text>
                               No matter if you want to redesign your services, add personalised functionality based on data analytics to your existing services or simply have a new idea you want to explore and need some help or sounding board, get in touch to start the conversation.
                           </Card.Text>
                       </Card.Body>
                   </Card>
               </Col>
               <Col>
                   <Card style={{height:'100%'}}>
                       <Card.Body>
                           <Card.Title>Actionable Insights</Card.Title>
                           <Card.Text>
                               Become proactive and retain your donors, one contact at a time.
                           </Card.Text>
                       </Card.Body>
                   </Card>
               </Col>
               <Col>
                   <Card style={{height:'100%'}}>
                       <Card.Body>
                           <Card.Title>Data science and Analytics</Card.Title>
                           <Card.Text>
                              Automate and personalise your activities, no matter if it is retention, acquisition, targeted campaigns or back-office processes.
                           </Card.Text>
                       </Card.Body>
                   </Card>
               </Col>
           </Row>
        </Container>
    )
}

const Clients = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    return (
        <Container className={"clients"} id={"clients"} style={{padding:'100px 40px'}}>
            <h5 style={{marginBottom:20}}>Great companies we've worked with</h5>
            <Row>
                <Col>
                    <div>
                        <Slider {...settings}>
                            <div>Unisphere Solutions</div>
                            <div>MTF Finance</div>
                            <div>Cybertribe</div>
                            <div>Datasourcery</div>
                            <div>Pro-care</div>
                        </Slider>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

const Pricing = () => {
    return (
        <Container id={"pricing"} style={{padding:40}}>
            <h1 className="text-center" style={{marginBottom:20}}>Pricing</h1>
        </Container>
    )
}

const Projects = () => {
    return (
        <Container id={"projects"} style={{padding:40}} className={"projects"}>
            <h1 className="text-center" style={{marginBottom:20}}>What we do</h1>
            <Row style={{padding:'40px 0'}}>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>Consulting</Card.Title>
                            <Card.Text>
                                Text
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>Power BI</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>Management reporting</Card.Title>
                            <Card.Text>
                                Text
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>Finance insight</Badge>
                            <Badge>Competitive intelligence</Badge>
                            <Badge>Workforce</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>Analysis</Card.Title>
                            <Card.Text>
                                Text.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>RPA</Badge>
                            <Badge>Javascript</Badge>
                            <Badge>ETL</Badge>
                            <Badge>Integration</Badge>
                            <Badge>Database design</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            <Row style={{padding:'40px 0'}}>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>Dashboards</Card.Title>
                            <Card.Text>
                                Text
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>Power BI</Badge>
                            <Badge>Tableau</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>KPI development and tracking</Card.Title>
                            <Card.Text>
                                Test
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>Pay Insight</Badge>
                            <Badge>Competitive intelligence</Badge>
                            <Badge>Workforce Analytics</Badge>
                            <Badge>Machine Learning</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card style={{height:'100%'}}>
                        <Card.Body>
                            <Card.Title>Market comparisons</Card.Title>
                            <Card.Text>
                                Test.
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Badge>Pay Analysis</Badge>
                            <Badge>Recruitment Insight</Badge>
                            <Badge>Data services</Badge>
                            <Badge>Segmentations</Badge>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const Contact = () => {

    const { register, handleSubmit } = useForm();
    const formId = '6'; //TIQ-Payroll
    const [email,setEmail] = useState('');
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [notes,setNotes] = useState('');
    const [valid,setValid] = useState(false);
    const [sent,setSent] = useState(false);

    // const onSubmit = async (data) => {
    //     handleActiveCampaignSubmit(data, 'datasourcery', formId)
    // }

    function onSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch('https://datasourcery.activehosted.com/proc.php', {
            method: 'POST',
            body: data,
            mode: 'no-cors',
        })
            .then(response => {
                console.log(response)
                setSent(true);
                setTimeout(() => {
                    setSent(false);
                }, 10000);
            })
            .catch(error => console.log('Request failed', error));
    }

    useEffect(() => {
        if(email !== '' && name !== '' && phone !== '' && notes !== '') {
            setValid(true)
        }else{
            setValid(false)
        }
    })

    return (
        <Container className={"contact"} id={"contact"} style={{padding:40}}>
            <h1 className="text-center" style={{marginBottom:20, fontSize:'3.5em'}}>
                Questions?<br />
                Feel free to contact us.
            </h1>
            <Row>
                <Col xs lg={2}></Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={onSubmit}>

                                <input type="hidden" name="u" value={formId} />
                                <input type="hidden" name="f" value={formId} />
                                <input type="hidden" name="s" />
                                <input type="hidden" name="c" value="0" />
                                <input type="hidden" name="m" value="0" />
                                <input type="hidden" name="act" value="sub" />
                                <input type="hidden" name="v" value="2" />

                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={name}
                                                  onChange={(e) => {
                                                      console.log(e);
                                                      setName(e.target.value)
                                                  }} name="name"
                                    />
                                    {(name === '') && (
                                        <p className="text-warning">Please provide an name</p>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control value={email}
                                                  error={(email === '')}
                                                  onChange={(e) => setEmail(e.target.value)}
                                                  name="email" type="email"
                                                  />
                                    {(email === '') && (
                                        <p className="text-warning">Please provide an email</p>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control value={phone}
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  name="phone" type="phone"/>
                                    {(phone === '') && (
                                        <p className="text-warning">Please provide an contact number</p>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="field[13]">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={10}  value={notes}
                                                  onChange={(e) => setNotes(e.target.value)}
                                                  name="field[13]"/>
                                    {(notes === '') && (
                                        <p className="text-warning">Please provide some context</p>
                                    )}
                                </Form.Group>

                                <Button disabled={(valid === false)} type={"submit"}>Submit</Button>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs lg={2}></Col>
            </Row>
        </Container>
    )
}

const Footer = () => {
    return (
        <Container id={"footer"} style={{padding:40}}>
            <h5>All rights reserved 2018-2021 @ Transformation IQ</h5>
            <p>
                <a href={""}>Terms & conditions</a>
                <a style={{marginLeft:20}} href={""}>Privacy statement</a>
            </p>
        </Container>
    )
}

export default function Home() {
    return (
        <div>
            <NavMenu />
            <Hero />
            <Features />
            <Clients />
            <Projects />
            <Pricing />
            <Contact />
            <Footer />
        </div>
    );
}
